exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-events-page-js": () => import("./../../../src/templates/eventsPage.js" /* webpackChunkName: "component---src-templates-events-page-js" */),
  "component---src-templates-posts-page-js": () => import("./../../../src/templates/postsPage.js" /* webpackChunkName: "component---src-templates-posts-page-js" */),
  "component---src-templates-sertifikat-js": () => import("./../../../src/templates/Sertifikat.js" /* webpackChunkName: "component---src-templates-sertifikat-js" */),
  "component---src-templates-single-event-js": () => import("./../../../src/templates/singleEvent.js" /* webpackChunkName: "component---src-templates-single-event-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/singlePost.js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}

